
import defaultFieldProps from '~/components/FlexibleSections/Blocks/defaultFieldProps';

export default {
    mixins: [
        defaultFieldProps
    ],
    props: {
        header: {
            type: String,
            default: null
        },
        cards: {
            type: Array,
            default() { return null; }
        },
        background: {
            type: String,
            default: 'default'
        }
    },
    methods: {
        getImage(card) {
            return card?.cardImages?.[0] ?? card?.images?.[0];
        }
    },
};
