import { render, staticRenderFns } from "./BaseCard.vue?vue&type=template&id=eb74cfb2"
import script from "./BaseCard.vue?vue&type=script&lang=js"
export * from "./BaseCard.vue?vue&type=script&lang=js"
import style0 from "./BaseCard.less?vue&type=style&index=0&prod&lang=less&external"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ImageAsset: require('/vercel/path0/components/ImageAsset/ImageAsset.vue').default,BaseIcon: require('/vercel/path0/components/BaseIcon/BaseIcon.vue').default,NavLink: require('/vercel/path0/components/NavLink/NavLink.vue').default})
